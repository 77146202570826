import React from 'react';
import Component from './Component'; // Assuming your component is saved in Component.js

function App() {
  return (
    <div className="App">
      <Component />
    </div>
  );
}

export default App;
