import React, { useEffect, useState, useRef } from 'react';

export default function Component() {
  const [manifesto, setManifesto] = useState('');
  const [tools, setTools] = useState([]);
  const canvasRef = useRef(null);

  useEffect(() => {
    const generateManifesto = () => {
      return `At Behr Labs, we believe that the future of tech shouldn't be about surrendering to the cloud, but about harnessing the power of your own devices. We can already run 3D worlds and complex simulations in our browsers, so why are we still relying on cloud-based services for simple tasks like file conversion, image editing, and more?


      It doesn't make sense to us that we can render entire virtual worlds in our browsers, but still need to upload our sensitive data to remote servers for everyday tasks. Cloud-based services can be slow, expensive, and worst of all, they often come at the cost of our privacy. 
    

      We're building software that runs locally, on your device, using the power of AI and machine learning. Our tools will be fast, private, and secure. No more waiting for cloud servers, no more worrying about data breaches. We're not trying to change the world, but we do want to make it a bit better. Join us in creating a more decentralized, more private, and more secure internet.


      `;
    };

    setManifesto(generateManifesto());

    const generateTools = () => {
      return [
        {
          title: "Anonpix Converter",
          description: "Convert and anonymize your images locally, ensuring privacy and security. No cloud uploads required.",
          buttonText: "Try Anonpix",
          buttonLink: "https://anonpix.behrlabs.com/"
        }
      ];
    };

    setTools(generateTools());

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const bubbles = [
      { x: canvas.width * 0.3, y: canvas.height * 0.3, radius: 300, color: '#4A00FF' },
      { x: canvas.width * 0.7, y: canvas.height * 0.7, radius: 250, color: '#3100E0' },
      { x: canvas.width * 0.5, y: canvas.height * 0.5, radius: 200, color: '#1A00C8' },
      { x: canvas.width * 0.2, y: canvas.height * 0.8, radius: 180, color: '#0D7EA0' }, // Added green-blue
      { x: canvas.width * 0.8, y: canvas.height * 0.2, radius: 220, color: '#15A0C0' }, // Added green-blue
      { x: canvas.width * 0.4, y: canvas.height * 0.6, radius: 280, color: '#02A0BD' }, // Added green-blue
    ];

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      bubbles.forEach((bubble, index) => {
        bubble.x += Math.sin(Date.now() * 0.001 + index) * 1.5; // Increased speed
        bubble.y += Math.cos(Date.now() * 0.001 + index) * 1; // Increased speed

        const gradient = ctx.createRadialGradient(
          bubble.x, bubble.y, 0,
          bubble.x, bubble.y, bubble.radius
        );
        gradient.addColorStop(0, `${bubble.color}80`);
        gradient.addColorStop(1, `${bubble.color}00`);

        ctx.beginPath();
        ctx.arc(bubble.x, bubble.y, bubble.radius, 0, Math.PI * 2);
        ctx.fillStyle = gradient;
        ctx.fill();
      });

      ctx.globalCompositeOperation = 'screen';

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen flex flex-col relative overflow-hidden bg-gray-900">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full filter blur-3xl"
      />
      <div className="absolute inset-0 bg-gray-900/30 backdrop-blur-sm z-10" />
      <div className="relative z-20 flex-grow flex flex-col items-center justify-center p-6 md:p-12">
        <div className="w-full max-w-3xl bg-gray-800/40 backdrop-blur-md p-6 rounded-lg shadow-lg mb-8">
          <img 
            src="/images/behrlabs.png" 
            alt="Behr Labs Logo" 
            className="mx-auto mb-6 w-auto h-auto max-w-[150px] max-h-[75px] object-contain"
          />
          <div className="prose prose-lg prose-invert">
            {manifesto.split('\n\n').map((paragraph, index) => (
              <p key={index} className="mb-4 text-gray-300">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
        <div className="w-full max-w-3xl bg-gray-800/40 backdrop-blur-md p-4 sm:p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-white mb-4">Our Tools</h2>
          <hr className="border-white mb-6" />
          {tools.map((tool, index) => (
            <div key={index} className="mb-6">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex-grow sm:max-w-[60%]">
                  <h3 className="text-xl font-semibold text-white mb-2">{tool.title}</h3>
                  <p className="text-gray-300 text-sm sm:text-base">{tool.description}</p>
                </div>
                <a
                  href={tool.buttonLink}
                  className="relative inline-block px-6 py-2 sm:px-8 sm:py-3 text-base font-medium text-white bg-transparent border border-white rounded-full overflow-hidden transition-all duration-300 ease-out group hover:border-opacity-50 hover:scale-105 w-full sm:w-auto text-center whitespace-nowrap"
                >
                  <span className="relative z-10 transition-colors duration-300 ease-out group-hover:text-white/90">
                    {tool.buttonText}
                  </span>
                  <div className="absolute inset-0 bg-white/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out"></div>
                  <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out">
                    <div className="absolute inset-0 bg-gradient-radial from-white/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out blur-xl"></div>
                  </div>
                </a>
              </div>
              {index < tools.length - 1 && <hr className="border-gray-600 my-6 border-t-2" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
